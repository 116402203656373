<template>
  <div id="addresslist">
    <top :name="text" />
    <!-- 地址管理 -->
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select ="select"
    >
    <!-- {{list}} -->
      <van-empty
        class="custom-image"
        image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        description="没有找到您的收货地址~"
        v-if="list.length == 0"
      />
    </van-address-list>
    <!--  :disabled-list="disabledList" disabled-text="以下地址超出配送范围" -->
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";
export default {
  data() {
    return {
      chosenAddressId: "1",
      text: "地址管理",
      list: [

      ],
      // disabledList: [
      //   {
      //     id: "3",
      //     name: "王五",
      //     tel: "1320000000",
      //     address: "浙江省杭州市滨江区江南大道 15 号",
      //   },
      // ],
    };
  },
  components: {
    top,
  },
  methods: {
    onAdd() {
      // ('新增地址');
      this.$router.push({ name: "addressup", params: { id: "新增地址" } });
    },
    onEdit(item, index) {
        console.log(item)
      // Toast('编辑地址:' + index);
      this.$router.push({ name: "addressup", params: { id: "编辑地址",data:JSON.stringify(item) } });
    },

    select(item){
      if(sessionStorage.getItem("id") =='900'){
        // 是否是从预订界面跳转过来的 或者是购物车
        sessionStorage.setItem("dizhi",JSON.stringify(item))
        this.$router.go(-1)
        sessionStorage.removeItem("id")
      }
    },
  },
  mounted() {
    //kon.png
    // malldock/queryaddress
    // 如果是从预订界面跳转过来将id存入缓存中，离开清除

    this.$https(
      "malldock/queryaddress",
      "phone=" +
        sessionStorage.getItem("user_phone") +
        "&id=" +
        sessionStorage.getItem("user_id"),
      "post"
    ).then((res) => {
      if(res.data.code =='000000'){
        res.data.data.forEach((item,index)=>{
          this.$set(this.list, index,item)
        })
      }else{
        // 无数据
      }

    });
  },
};
</script>

<style scoped>
#addresslist {
  height: 100%;
  background: #f8f8f8;
}
</style>